import { featuredNewsImageConfigurations } from '../../../imageConfigurations';

export const spotlightVariables = {
  featured: true,
  offset: 0,
  limit: 1,
  tagSlugs: ['spotlight'],
  inDays: 1,
  imageConfigurations: featuredNewsImageConfigurations,
};

const featuredVariables = {
  featured: true,
  offset: 0,
  limit: 4,
  sort: 'CREATED_AT',
  statuses: ['PUBLISHED'],
  imageConfigurations: featuredNewsImageConfigurations,
};

export default featuredVariables;
