import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import withQuery from '../../../../lib/withQuery';
import messages from '../../../../lib/messages.mjs';
import articlesQuery from '../../../../lib/queries/news/articlesQuery.graphql';
import { spotlightVariables } from
'../../../../lib/queries/news/variables/featured';
import { featuredCategoryIds } from '../../../../lib/mainCategoryIds';
import { useSiteContext } from '../../../SiteContext';
import getFeaturedVariables from '../../../../lib/getFeaturedVariables';
import loading from '../../../../lib/loading';

const FeaturedSection = dynamic(() => import('./FeaturedSection'), {
  ssr: true,
  loading,
});

const AdditionalNewsSections = dynamic(
  () => import('../AdditionalNewsSections'), {
    ssr: false,
    loading,
  },
);

const Top = ({
  data,
  variables,
  spotlightNewsArticle,
  showCreatedAt,
  expandedSections,
  showLoader,
  categorySection,
}) => {
  const intl = useIntl();

  const articles = Array.isArray(data?.universalNewsArticles)
    ? data.universalNewsArticles
    : [];

  const featuredArticles = spotlightNewsArticle
    ? [spotlightNewsArticle, ...articles]
    : articles;

  const notInIds = featuredArticles.map(
    (item) => item?.id,
  ).filter((id) => id != null);

  return (
    <>
      <FeaturedSection
        title={intl.formatMessage(messages.featured)}
        variables={variables}
        featuredArticles={featuredArticles}
        showCreatedAt={showCreatedAt}
        showLoader={showLoader}
      />
      <AdditionalNewsSections
        variables={variables}
        notInIds={notInIds}
        showCreatedAt={showCreatedAt}
        expandedSections={expandedSections}
        showLoader={showLoader}
        categorySection={categorySection}
      />
    </>
  );
};

Top.propTypes = {
  data: PropTypes.shape({
    universalNewsArticles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  variables: PropTypes.shape({}).isRequired,
  spotlightNewsArticle: PropTypes.shape({}),
  expandedSections: PropTypes.bool.isRequired,
  showCreatedAt: PropTypes.bool,
  showLoader: PropTypes.bool,
  categorySection: PropTypes.node,
};

Top.defaultProps = {
  data: {},
  spotlightNewsArticle: null,
  showCreatedAt: false,
  showLoader: true,
  categorySection: null,
};

const TopNewsWithQuery = withQuery(Top, { renderAlways: true });
const errorMessage = 'Error loading news articles...';

const TopNewsSection = ({
  variables,
  showCreatedAt,
  expandedSections,
  moreNewsVariables,
  categorySection,
}) => {
  const { newsboard } = useSiteContext();
  const featuredSpotlightVariables = {
    ...spotlightVariables,
    withoutSummaryAndLabel: false,
    withoutDescriptionAndCreator: false,
    newsboard,
    categoryIds: featuredCategoryIds,
    ...variables,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TopNewsWithData = ({ data, showLoader }) => {
    const spotlightNewsArticle = data?.universalNewsArticles?.[0];
    const notInIds = spotlightNewsArticle ? [spotlightNewsArticle.id] : [];

    return (
      <TopNewsWithQuery
        query={articlesQuery}
        variables={getFeaturedVariables(
          newsboard,
          notInIds,
          variables,
        )}
        skip={showLoader}
        errorMessage={errorMessage}
        spotlightNewsArticle={spotlightNewsArticle}
        showCreatedAt={showCreatedAt}
        expandedSections={expandedSections}
        moreNewsVariables={moreNewsVariables}
        categorySection={categorySection}
        loader={<HiddenLoader />}
      />
    );
  };

  TopNewsWithData.propTypes = {
    data: PropTypes.shape({
      universalNewsArticles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    showLoader: PropTypes.bool,
  };

  TopNewsWithData.defaultProps = {
    data: {},
    showLoader: false,
  };

  const TopNewsWithDataWithSpotlightQuery = withQuery(TopNewsWithData, {
    renderAlways: true,
  });

  return (
    <TopNewsWithDataWithSpotlightQuery
      query={articlesQuery}
      variables={featuredSpotlightVariables}
      errorMessage={errorMessage}
      loader={<HiddenLoader />}
    />
  );
};

TopNewsSection.propTypes = {
  variables: PropTypes.shape({}),
  moreNewsVariables: PropTypes.shape({}),
  expandedSections: PropTypes.bool.isRequired,
  showCreatedAt: PropTypes.bool,
  categorySection: PropTypes.node,
};

TopNewsSection.defaultProps = {
  variables: null,
  moreNewsVariables: null,
  showCreatedAt: false,
  categorySection: null,
};

export default TopNewsSection;
