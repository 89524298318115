import { featuredCategoryIds } from './mainCategoryIds';
import featured from
  './queries/news/variables/featured';

const getFeaturedVariables = (
  newsboard: string,
  notInIds: number[],
  variables: object,
) => ({
  newsboard,
  withoutSummaryAndLabel: false,
  withoutDescriptionAndCreator: false,
  ...featured,
  notInIds,
  categoryIds: featuredCategoryIds,
  ...variables,
});

export default getFeaturedVariables;
